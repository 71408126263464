import axios from '../axios'
/**
 * REQUEST FORM
 * /{controllerName}/{function}
 */

export async function getCarInfoList() { 
  const res = await axios.post('/carInfo/getCarInfoList')
  return res
}

export async function getCarInfo(param) {
  console.log(param)
  const res = await axios.post('/carInfo/getCarInfo', param)
  return res
}


export async function getMyCar() { 
  const res = await axios.post('/carInfo/getMyCar')
  return res
}

export async function saveCarInfo(info) { 
  const res = await axios.post('/carInfo/saveCarInfo', info) 
  return res
}

export async function removeCar(index) { 
  const res = await axios.post('/carInfo/removeCar', {index : index}) 
  return res
} 