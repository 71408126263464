<template>
    <v-row class="flex-column">
        <v-col class="d-flex align-start">
            <span :style="`color:${this.$vuetify.theme.currentTheme.popularUseColor}`">{{ title }}</span>
            <v-icon v-if="isRequired" style="font-size:xx-small" color="red darken-2">mdi-asterisk</v-icon>
        </v-col>
        <v-col>
            <v-row>
                <v-col>
                    <v-radio-group v-model="select" :rules="rules" >
                        <v-radio
                            v-for="(item, key) in options"
                            :key="key"
                            :label="item"
                            :value="key"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'RadioComponent',
    data(){
        return{
            select: null
        }
    },
    props: {
        title:{ // 입력란 제목
            default: '제목',
            required: true
        },
        isRequired:{ // 필수입력사항인지
            default: false,
            type: Boolean
        },
        options: {
            type: Object,
            required: true
        },
        rules:{ // 입력 규칙
            default: function () {
                return []
            },
            type:Array,
            validator: function (value) { // props 유효성검사
                // 값이 항상 아래 세 개의 문자열 중 하나여야 합니다. 
                return Array.isArray(value)
            }
        },
        errorMessages:{ // 에러메세지
            default: ''
        },
        successMessages:{ // 성공메세지
            default: ''
        },
    },
    watch: {
        select(val) {
            this.$emit('update:vModel', val)
        }
    }
}
</script>

