<template>
  <v-main :style="`background:${$vuetify.theme.currentTheme[layoutOptions.mainBackground]}`">
    <v-fade-transition
      mode="out-in"
    >
      <router-view :key="$route.path"/> 
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'DefaultView',
    props:{
       layoutOptions:{
        type: Object
      }
    },
  }
</script>
