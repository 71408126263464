<template>
    <common-text-field :title="$t('MailVerification.email')" :placeholder="$t('MailVerification.emailPlaceHolder')"  :vModel.sync="email" :rules="[rules.required]" setSlot="append-outer" type="text" :errorMessages="errorMessages" :successMessages="successMessages" :isRequired="true">
        <v-btn  class="px-2 mailBtn"  slot="innerSlot" text style="white-space:normal;display:contents;" color="mainColor" @click="sendCertificationNumber" :disabled="!disabledBtn">
            {{ isCertify ? $t('MailVerification.authComplete') : $t('MobileVerification.sendAuth') }}
        </v-btn>
        
        <bottom-popup slot="appendSlot" :title="$t('MailVerification.emailAuth')" :isShow.sync="showPopup">
            <v-card class="pa-0 d-flex flex-column align-center" tile flat style="position:relative">
                <v-card-text :style="`color:${this.$vuetify.theme.currentTheme.popularUseColor}`" class="text-center">{{ $t('MailVerification.timeRemaining') }}</v-card-text>
                <v-progress-circular
                    :rotate="-90"
                    :size="100"
                    :width="15"
                    :value="timerGraph"
                    color="mainColor"
                >
                    {{ secToMin }}
                </v-progress-circular>
                <v-text-field
                    v-model="inputNumber"
                    color="mainColor"
                    :label="$t('MailVerification.authNumber')"
                    type='number'
                ></v-text-field>
                <submit-button class="d-flex" :disabled="! inputNumber != ''  || timer.time > timer.limit" @click.native="mailCertification" :absolute="false">{{ $t('MailVerification.authenticate') }}</submit-button>
            </v-card>
        </bottom-popup>
    </common-text-field>
</template>

<script>

import { get } from 'vuex-pathify'

export default {
    name: 'MailVerification',
    data(){
        return{
            formValid: false,
            showPopup: false, 
            isCertify: false,
            errorMessages: '',
            successMessages: '',
            certifyNumber: '',
            email: '',
            inputNumber : '',
            disabledBtn : false,
            axios : false, 
            timer:{ // 인증번호 타이머 관련
                limit: 900, // 입력제한시간(단위 sec)
                time: 0, // 지나는 시간을 담을 변수
                interval: {}, // 입력 시간 인터벌
            },
            isModify: false,
        }
    },
    props: {
        vModel:{ // v-model
            default: ''
        },
        notCheckDuplicate : { // 이전 findInfo
            type : Boolean,
            default : false
        },
        isModi : {
            type : Boolean,
            default : false
        }
    },
    methods:{
        vModelReturn(){
            this.$emit('update:vModel', this.email)
            this.vModelStr = ''
        },
        async sendCertificationNumber(){ // 인증번호 발송
            if(this.axios) {
                return
            }
            // 인증 관련정보 초기화
            this.isCertify = false      // 인증완료 여부
            this.inputNumber = ''       // 사용자 입력 인증번호 초기화

            let sendData = {
                email : this.email,
                notCheckDuplicate : this.notCheckDuplicate
            }

            this.axios = true
            const sendCertifyMail = await this.$api.thirdpartyApi.sendCertifyMail(sendData);
            
            if(sendCertifyMail.code === this.$constant.RESPONSE.MAIL_SEND_SUCCESS.code){
                this.showPopup = true 
                this.certifyNumber = sendCertifyMail.result.certifyNumber
                this.startCertificationTimer() 
                console.log(this.certifyNumber)
            }

            this.axios = false
        }, 
        mailCertification(){ // 인증하기
            this.errorMessages = this.successMessages = "" // 메세지 초기화
            
            if(this.certifyNumber == this.inputNumber){ 
                this.disabledBtn = false
                this.showPopup = false // 팝업 닫기
                this.isCertify = true // 인증완료
                this.successMessages =  this.$t('MailVerification.authCompleteAlert')
                this.vModelReturn()
                clearInterval(this.timer.interval) 
            }else{ 
                this.isCertify = false // 인증 실패
                this.errorMessages = this.$t('MailVerification.authFail')
                this.$function.alert(this.$t('MailVerification.authFailAlert'), 'warning')
            }
        },
        startCertificationTimer(){ // 인증번호 입력 타이머
            this.timer.time = 0 // 시간 초기화
            clearInterval(this.timer.interval) // 인터벌 초기화
            this.timer.interval = setInterval(() => { // 1초 인터벌
                this.timer.time += 1
            }, 1000)
        },
        setBtnMargin() {
            this.$nextTick(function () {
                document.documentElement.style.setProperty('--btnMarginTop', document.querySelector('.mailBtn').parentElement.clientHeight > 19 ? '0px' : '8px');
            })
        }   
    },
    watch:{
        email:{
            handler: function(val){
                if(this.isModify) {
                    this.isCertify = true
                    this.isModify = false
                } else {
                    this.isCertify = false
                    if( /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(val)) {
                        this.errorMessages = this.$t('MailVerification.proceedAuth')
                        this.disabledBtn = true
                    } else {
                        this.errorMessages = this.$t('MailVerification.checkMail')
                    }
                }
            },
            deep: true
        }, 
        isModi : {
            handler : function (val) {
                this.isModify = val
            }, 
            immediate: true,
        },
        vModel(val) {
            if(val != '') {
                this.email = val
            }
        },
        isCertify(val) { 
            this.setBtnMargin()
        }
    },
    computed:{
        ...get('AccountConstant',['rules']),
        secToMin(){ // 핸드폰 인증 남은 시간을 분 단위로 보여줌
            let remainingTime = this.timer.limit - this.timer.time
            let min = Math.floor(remainingTime / 60)
            let sec = remainingTime % 60
            return remainingTime >= 0 ? `${min} : ${sec.toString().padStart(2, '0')}` : this.$i18n.t('MailVerification.overTime')
        },
        timerGraph(){ // 남은시간 타이머 그래프 밸류 계산
            return this.timer.time / (this.timer.limit / 100)
        },
    },
    created() { 
        this.setBtnMargin() 
    }
}
</script>

<style scoped>
::v-deep .v-input__append-outer {
    display:block;
    margin-top : var(--btnMarginTop);
}

::v-deep .v-label {
    max-width: 100%;
}
</style>
