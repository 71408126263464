<template>
    <naver-maps 
        :height="height" 
        :mapOptions="mapOptions"
    >
        <naver-marker :lat="lat" :lng="lng" ></naver-marker>
    </naver-maps>
</template>

<script>
export default {
    name: 'NaverMapComponent',
    props: {
        mapOptions: { 
            type: Object,
            required: true
        },
        lat:{
            type: Number,
        },
        lng: {
            type: Number,
        },
        height: {
            type:Number,
            default: 300
        },
    },
}
</script>