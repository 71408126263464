<template>
    <v-row class="flex-column">
        <v-col class="d-flex align-start">
            <span :style="`color:${this.$vuetify.theme.currentTheme.popularUseColor}`">{{ title }}</span>
            <v-icon v-if="isRequired" style="font-size:xx-small" color="red darken-2">mdi-asterisk</v-icon>
        </v-col>
        <v-col>
            <v-row>
                <v-col>
                    <v-select
                    v-model="input"
                    :items="selectItems"
                    item-text="text"
                    item-value="value"
                    return-object
                    single-line
                    @change="handleInput"
                    ></v-select>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CommonSelect',
    data(){
        return{
            input: []
        }
    },
    props: {
        title:{ // 입력란 제목
            default: '제목',
            required: true
        },
        isRequired:{ // 필수입력사항인지
            default: false,
            type: Boolean
        },
        placeholder:{ // 플레이스홀더
            default: ''
        }, 
        vModel:{ // v-model
            default: ''
        },
        selectItems : { // {text : '', value : ''} 
            required: true,
            type:Array,
        },
        rules:{ // 입력 규칙
            default: function () {
                return []
            },
            type:Array,
            validator: function (value) { // props 유효성검사
                // 값이 항상 아래 세 개의 문자열 중 하나여야 합니다. 
                return Array.isArray(value)
            }
        },
        defaultSelect : {   // 페이지로드시 select되어있어야할때 { text : '', value : ''}로
            default: function () {
                return {}
            },
            required: true,
            type:Object,
        }
    },
    methods:{
        handleInput($e) {
            this.input = $e
            this.$emit('update:vModel',  this.input.value)
        },
    },
    watch: {
        defaultSelect() {
            this.input = this.defaultSelect
        }
    }
}
</script>