<template>
    <v-btn icon :ripple="false" @click="setFavorite">
        <v-icon :color="additionalBtnColor" v-if="!isFavorite">
            mdi-star-outline
        </v-icon>
         <v-icon :color="additionalBtnColor" v-else>
            mdi-star
        </v-icon>
    </v-btn>
</template>

<script>

export default {
    name: 'Star',
    data() {
        return {
            place_id : this.$route.params.place_id,
            isFavorite : false
        }
    },
    props:{
        additionalBtnColor:{
            type:String
        },
    },
    async created() {
        const res = await this.$api.chargeApi.favoriteCheck(this.place_id) 
        if(res.code == this.$constant.RESPONSE.SUCCESS.code) {
            if(res.result.length > 0) {
                this.isFavorite = true
            }
        }
    },
    methods : {
        async setFavorite() {
            if(!this.isFavorite) {
                const res = await this.$api.chargeApi.setFavoritePlace(this.place_id)
                if(res.code == this.$constant.RESPONSE.SUCCESS.code) {
                    this.isFavorite = true
                }
            } else {
                const res = await this.$api.chargeApi.removeFavorite(this.place_id)
                if(res.code == this.$constant.RESPONSE.SUCCESS.code) {
                    this.isFavorite = false
                }
            }
        }
    }
}
</script>