<template>
  <v-navigation-drawer v-model="leftMenu" fixed temporary :touchless="true" width="80%">
    <v-list>
      <div class="d-flex justify-end px-2">
        <v-btn icon small @click="leftMenu = !leftMenu"><v-icon small>mdi-close</v-icon></v-btn>
      </div>
      <v-list-item >
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{userInfo.name}} {{userInfo.lastName}}
          </v-list-item-title>
          <v-list-item-subtitle> 
            {{userInfo.userName}} 
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <div v-for="(menu, index) in menus" :key="index">
        <v-list-item v-if="(menu.items == 0 || menu.items == undefined) && menu.title.toLowerCase() !== 'contact'" link :to="menu.to">
          <v-list-item-icon class="mr-3">
            <v-icon>{{ menu.action }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-else-if="(menu.items == 0 || menu.items == undefined) && menu.title.toLowerCase() === 'contact'"
          @click="openWhatsapp">
          <v-list-item-icon class="mr-3">
            <v-icon>{{ menu.action }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
        <v-list-group v-else :key="menu.title" v-model="menu.active" no-action>
          <template v-slot:activator>
            <v-list-item-icon class="mr-3">
              <v-icon>{{ menu.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </template>

          <v-list-item v-for="child in menu.items" :key="child.title" :to="child.to" class="pl-10">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'LeftMenu',
  data() {
    return {
      userInfo: {},
      menus: [
        {
          title: 'My Profile',
          action: 'mdi-account',
          to: {
            name: this.$pages.MY_PAGE.name
          }
        },
        {
          title: 'My Vehicles',
          action: 'mdi-car',
          to: {
            name: this.$pages.MY_CAR.name
          }
        },
        {
          title: this.$i18n.t('MyPage.list.history.chargingHistory'),
          action: 'mdi-history',
          to: {
            name: this.$pages.CHARGING_HISTORY_PERIOD.name
          }
        },
        {
          title: this.$i18n.t('MyPage.list.history.cashHistory'),
          action: 'mdi-clock',
          to: {
            name: this.$pages.CASH_HISTORY_LIST.name
          }
        },
        {
          title: this.$i18n.t('MyPage.list.brokenReport.title'),
          action: 'mdi-hammer-screwdriver',
          items: [
            { title: this.$i18n.t('MyPage.list.brokenReport.report'), to: this.$pages.BROKEN_REGI.name },
            { title: this.$i18n.t('MyPage.list.brokenReport.reportList'), to: this.$pages.BROKEN_LIST.name }
          ]
        },
        {
          title: this.$i18n.t('MyPage.list.board.title'), action: 'mdi-bulletin-board',
          items: [
            { title: this.$i18n.t('MyPage.list.board.notice'), to: {name : this.$pages.BOARD_LIST.name, params: { type: 'Notice' } }},
            { title: this.$i18n.t('MyPage.list.board.faq'), to: {name :this.$pages.BOARD_LIST.name, params: { type : 'Faq' }} },
            { title: this.$i18n.t('MyPage.list.board.manual'), to: {name : this.$pages.BOARD_LIST.name, params: { type: 'Manual' }} },
          ]
        },
        {
          title: 'About',
          action: 'mdi-information',
          to: {
            name: this.$pages.ABOUT.name
          }
        },
        {
          title: 'Contact',
          action: 'mdi-contacts'
        }
      ],
    }
  },
  created() {
  },
  methods: {
    async getInfo() {
        const myInfo = await this.$api.userApi.getInfo()

        if(myInfo.code === this.$constant.RESPONSE.SUCCESS.code){
            this.userInfo = {
                name: myInfo.result.name,
                lastName: myInfo.result.lastName,
                userName: myInfo.result.userName,
            }
        }
    },
    openWhatsapp() {
      const visitTime = new Date().getTime()
      // android인 경우, intent url을 통해 앱이 설치되어 있으면 app 실행, 설치 되어 있지 않으면 play store로 이동
      if(this.$function.isAndroidApp()) {
        location.href = `intent://send/${this.$keys.WHATSAPP_NUM}#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end`
        // location.href = `https://wa.me/${this.$keys.WHATSAPP_NUM}`
      }
      // ios인 경우
      else if(this.$function.isIosApp()) {
        // 설치 안되어 있으면, app store link로 이동
        setTimeout(() => {
          if(new Date().getTime() - visitTime < 3000) {
            location.href = 'itms-apps://itunes.apple.com/app/whatsapp-messenger/id310633997'
          }
        }, 2500)
        // 설치 되어 있으면, 앱 실행
        setTimeout(() => {
          location.href = `whatsapp://send?phone=${this.$keys.WHATSAPP_NUM}`
        }, 0)
      }
      // 모바일 디바이스가 아닌 경우, whatsapp Universal link로 연결
      else {
        setTimeout(() => {
          const a = document.createElement('a')
          a.href = `https://wa.me/${this.$keys.WHATSAPP_NUM}`
          a.setAttribute('target', '_blank')
          a.style.visibility = 'hidden'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }, 0)
      }
    }
  },
  computed: {
    leftMenu: sync('app/leftMenu'),
  },
  watch: {
    '$route.path': {
      handler(n) {
        if (n === '/home') {
          this.getInfo()
        }
      },
      immediate: true
    }
  }
}
</script>
