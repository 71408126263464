<template>
    <common-text-field :title="$t('MobileVerification.phoneNumber')" :isRequired="true" :placeholder="$t('MobileVerification.phoneNumberPlaceHolder')" :vModel.sync="phoneNumber.backNumber" :rules="[rules.required, rules.phoneNumberRegex]" setSlot="append-outer" type="number"
        :errorMessages="errorMessages" :successMessages="successMessages">
        <v-col slot="prependSlot" cols="3">
            <v-select
                v-model="phoneNumber.frontNumber"
                :items="frontNumberList"
                :rules="[rules.required]"
                dense
                class="ma-0 mr-2"
                :placeholder="$t('MobileVerification.select')"
            ></v-select>
        </v-col>

        <v-btn slot="innerSlot" text class="px-2" style="display:contents;" color="mainColor" @click="sendCertificationNumber" :disabled="certify.btnDisabled">
            {{ certify.limitCheck ? $t('MobileVerification.resend') : $t('MobileVerification.sendAuth') }}
        </v-btn>

        <bottom-popup slot="appendSlot" :title="$t('MobileVerification.phoneAuth')" :isShow.sync="certify.showPopup">
            <v-card class="pa-0 d-flex flex-column align-center" tile flat style="position:relative">
                <v-card-text :style="`color:${this.$vuetify.theme.currentTheme.faintColor}`" class="text-center">{{ `${$t('MobileVerification.limitSend')} : ${certify.limitCheck}/${certify.limit}` }}</v-card-text>
                <v-card-text :style="`color:${this.$vuetify.theme.currentTheme.popularUseColor}`" class="text-center">{{ $t('MobileVerification.timeRemaining') }}</v-card-text>
                <v-progress-circular
                    :rotate="-90"
                    :size="100"
                    :width="15"
                    :value="timerGraph"
                    color="mainColor"
                    >
                    {{ secToMin }}
                </v-progress-circular>
                <v-text-field
                    v-model="certify.inputNumber"
                    color="mainColor"
                    :label="$t('MobileVerification.authNumber')"
                    :autofocus="autofocus"
                ></v-text-field>
                <submit-button class="d-flex" :disabled="! certify.inputNumber != '' || timer.time > timer.limit" @click.native="phoneCertification" :absolute="false">{{ $t('MobileVerification.authenticate') }}</submit-button>
                <!-- <v-btn color="mainColor" class="text-h6 font-weight-bold" style="color:#fff; box-sizing:border-box !important"
                    x-large block :disabled="! certify.inputNumber != '' || timer.time > timer.limit" @click="phoneCertification"></v-btn> -->
            </v-card>
        </bottom-popup>
    </common-text-field>
</template>

<script>

import { get } from 'vuex-pathify'

export default {
    name: 'MobileVerification',
    data(){
        return{
            phoneNumber:{
                frontNumber: '', // 핸드폰 앞 3자리
                backNumber: '', // 핸드폰 뒷 8자리
            },
            certify:{ // 핸드폰 인증번호 관련
                inputNumber: '', // 사용자가 입력한 인증번호
                btnDisabled: true, // 인증발송 버튼 활성화 여부(핸드폰 번호 미입력 시 비활성화)
                showPopup: false, // 인증번호 팝업 열기
                isCertify: false, // 인증 완료 여부
                limit: 5, // 인증번호 발송 제한수
                limitCheck: 0, // 사용자가 발송 요청한 수
            },
            timer:{ // 인증번호 타이머 관련
                limit: 300, // 입력제한시간(단위 sec)
                time: 0, // 지나는 시간을 담을 변수
                interval: {}, // 입력 시간 인터벌
            },
            errorMessages: '',
            successMessages: '',
            certifyNumber: '',
        }
    },
    props: {
        vModel:{ // v-model
            default: ''
        },
        notCheckDuplicate : { // 이전 findInfo
            type : Boolean,
            default : false
        },
        autofocus:{
            default: false
        }
    },
    methods:{
        vModelReturn(){
            this.$emit('update:vModel', `${this.phoneNumber.frontNumber}${this.phoneNumber.backNumber}`)
        },
        async sendCertificationNumber(){ // 인증번호 발송
            // 핸드폰 인증 관련정보 초기화
            this.certify.isCertify = false // 인증완료 여부
            this.certify.inputNumber = '' // 사용자 입력 인증번호 초기화
            this.certifyNumber = '' // 인증번호 초기화
            if(this.certify.limitCheck < this.certify.limit){ // 발송제한 수보다 적을 경우
                const sendCertifySms = await this.$api.thirdpartyApi.sendCertifySms(`${this.phoneNumber.frontNumber}${this.phoneNumber.backNumber}`, this.notCheckDuplicate);
                if(sendCertifySms.code === this.$constant.RESPONSE.SEND_CERTIFICATION_NUMBER.code){
                    this.certify.limitCheck += 1 // 발송 요청수 +1
                    console.log(sendCertifySms.result.certifyNumber)
                    this.certifyNumber = sendCertifySms.result.certifyNumber
                    this.certify.showPopup = true // 팝업 열기
                    this.startCertificationTimer() // 인증번호 입력 타이머 시작!
                    this.$function.alert(sendCertifySms.detail)
                }
            }else{
                this.$function.alert(this.$i18n.t('MobileVerification.overLimitSendMessage'), 'warning')
            }
        },
        startCertificationTimer(){ // 인증번호 입력 타이머
            this.timer.time = 0 // 시간 초기화
            clearInterval(this.timer.interval) // 인터벌 초기화
            this.timer.interval = setInterval(() => { // 1초 인터벌
                this.timer.time += 1
            }, 1000)
        },
        phoneCertification(){ // 인증하기
            this.errorMessages = this.successMessages = "" // 메세지 초기화
            if(this.certifyNumber == this.certify.inputNumber){ // 발송한 인증번호와 입력한 인증번호가 같다면
                this.certify.showPopup = false // 팝업 닫기
                this.certify.isCertify = true // 인증완료
                this.successMessages = this.$i18n.t('MobileVerification.authComplete')
                this.$function.alert(this.$i18n.t('MobileVerification.authCompleteAlert'), 'success')
                this.vModelReturn()
            }else{ // 인증번호 틀렸을 경우
                this.certify.isCertify = false // 인증 실패
                this.errorMessages = this.$i18n.t('MobileVerification.authFail')
                this.$function.alert(this.$i18n.t('MobileVerification.authFailAlert'), 'warning')
            }
        },
    },
    watch:{
        phoneNumber:{
            handler: function(){
                this.certify.isCertify = false // 핸드폰번호 변경 감지 시 인증여부 false
                if(this.phoneNumber.frontNumber.length + this.phoneNumber.backNumber.length == 11){ // 핸드폰번호가 11자리일 경우(정규식은 이미 입력단계에서 진행)
                    this.certify.btnDisabled = false
                    this.errorMessages = this.$i18n.t('MobileVerification.proceedAuth')
                }else{
                    this.certify.btnDisabled = true
                }
            },
            deep: true
        },
    },
    computed:{
        secToMin(){ // 핸드폰 인증 남은 시간을 분 단위로 보여줌
            let remainingTime = this.timer.limit - this.timer.time
            let min = Math.floor(remainingTime / 60)
            let sec = remainingTime % 60
            return remainingTime >= 0 ? `${min} : ${sec.toString().padStart(2, '0')}` : this.$i18n.t('MobileVerification.overTime')
        },
        timerGraph(){ // 남은시간 타이머 그래프 밸류 계산
            return this.timer.time / (this.timer.limit / 100)
        },
        ...get('AccountConstant',['rules', 'frontNumberList'])
    }
}
</script>

<style scoped>
::v-deep .v-label {
    max-width: 100%;
}
</style>