import { isIosApp, isAndroidApp, alert } from '../util/function'
import i18n from '@/util/i18n'

export function RequestLoginInfo() { 
    if (isAndroidApp()) {
        return window.android.RequestLoginInfo();
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestLoginInfo.postMessage("")
    } 
}

export function SaveLoginInfo(uid, autoCheck, ukey, utoken) { 
    if (isAndroidApp()) {
        return window.android.SaveLoginInfo(uid, autoCheck, ukey, utoken)
    } else if (isIosApp()) {
        ukey = (ukey != "" ? ukey.toString() : "")
        autoCheck = autoCheck.toString()
        return window.webkit.messageHandlers.SaveLoginInfo.postMessage({uid: uid, autoCheck: autoCheck, ukey: ukey, utoken: utoken});
    } 
}

export function RequestFirebaseToken() { 
    if (isAndroidApp()) {
        return window.android.RequestFirebaseToken()
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestFirebaseToken.postMessage("")
    } 
}

export function PlaySound(soundStr) { // cable_connect, charge_start, charge_end
    if (isAndroidApp()) {
        return window.android.PlaySound(soundStr)
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.PlaySound.postMessage(soundStr)
    } 
}

export function RequestGps(reqPermission, reqSetting = true) {
    if (isAndroidApp()) {
        return window.android.RequestGps(reqPermission, reqSetting)
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestGps.postMessage({reqPermission: reqPermission, reqSetting: reqSetting})
    } 
}

export function getBase64FromBlobData(base64Data, originalname) { 
    if (isAndroidApp()) {
        return window.android.getBase64FromBlobData(base64Data, originalname)
    } else if (isIosApp()) { 
        return window.webkit.messageHandlers.getBase64FromBlobData.postMessage({base64Data: base64Data, originalname: originalname})
    } 
}

export function startGoogleNavi(path, start_lat, start_lng) { 
    if (isAndroidApp()) {
        return window.android.googleMapStart(path)
    } else if (isIosApp()) {
        alert(i18n.t('About.waitAMoment'))
        const visitTime = new Date().getTime()
        const splitedPath = path.split(',')
        const mapUrl = `comgooglemaps://?saddr=${start_lat},${start_lng}&daddr=${splitedPath[0]},${splitedPath[1]}&directionsmode=driving`

        // 구글 지도 앱 설치되지 않은 경우, App Store 페이지로 이동
        setTimeout(() => {
            if(new Date().getTime() - visitTime < 3000) {
                location.href = 'itms-apps://itunes.apple.com/app/google-maps/id585027354'
            }
        }, 2500)
        // 설치 되어 있으면, 앱 실행
        setTimeout(() => {
            location.href = mapUrl
        }, 0)

        // return window.webkit.messageHandlers.getBase64FromBlobData.postMessage({base64Data: base64Data, originalname: originalname})
    } 
}

export function openOutLink(url) {
    if (isAndroidApp()) {
        return window.android.openOutLink(url)
    } else if (isIosApp()) {
        alert(i18n.t('About.waitAMoment'))
        setTimeout(() => {
            return window.webkit.messageHandlers.OpenOutLink.postMessage({url: url})
        }, 0)
    }
}

export function openFacebook(url) {
    if (isAndroidApp()) {
        return window.android.openFacebook(url)
    } else if (isIosApp()) {
        alert(i18n.t('About.waitAMoment'))
        const id = url.split('facebook.com/')[1]
        const visitTime = new Date().getTime()
        // 설치 안되어 있으면, app store link로 이동
        setTimeout(() => {
            if(new Date().getTime() - visitTime < 3000) {
                location.href = 'itms-apps://itunes.apple.com/app/facebook/id284882215'
            }
        }, 2500)
        // 설치 되어 있으면, 앱 실행
        setTimeout(() => {
            location.href = `fb://page?id=${id}`
        }, 0)
    }
}

export function openInstagram(url) {
    if (isAndroidApp()) {
        const instagramId = url.split('instagram.com/')[1]
        return window.android.openInstagram(instagramId)
    } else if (isIosApp()) {
        alert(i18n.t('About.waitAMoment'))
        const visitTime = new Date().getTime()
        setTimeout(() => {
            if(new Date().getTime() - visitTime < 3000) {
                location.href = 'itms-apps://itunes.apple.com/app/instagram/id389801252'
            }
        }, 2500)
        // 설치 되어 있으면, 앱 실행
        setTimeout(() => {
            return window.webkit.messageHandlers.OpenInstagram.postMessage({url: url})
        }, 0)
        
    }
}

export function openLinkedin(url) {
    if (isAndroidApp()) {
        return window.android.openLinkedin(url)
    } else if (isIosApp()) {
        alert(i18n.t('About.waitAMoment'))
        const link = url.split('linkedin.com/')[1]
        const visitTime = new Date().getTime()
        // 설치 안되어 있으면, app store link로 이동
        setTimeout(() => {
            if(new Date().getTime() - visitTime < 3000) {
                location.href = 'itms-apps://itunes.apple.com/app/linkedin/id288429040'
            }
        }, 2500)
        // 설치 되어 있으면, 앱 실행
        setTimeout(() => {
            location.href = `linkedin://${link}`
        }, 0)
    }
}