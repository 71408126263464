<template>
    <v-card tile flat color="subBackgroundColor" height="420" class="pa-3" :style="`border:1px solid ${$vuetify.theme.currentTheme.faintColor} !important; border-width: 1px 0 1px 0`">
        <v-row class="justify-center align-center">
            <v-btn icon @click="prevMonth"><v-icon large>mdi-chevron-left</v-icon></v-btn>
            <v-card-title class="text-h5">{{ printMonth }}</v-card-title>
            <v-btn icon @click="nextMonth"><v-icon large>mdi-chevron-right</v-icon></v-btn>
        </v-row>
        <v-row class="py-3">
            <v-col class="text-center text-caption red--text darken-4">SUN</v-col>
            <v-col class="text-center text-caption">MON</v-col>
            <v-col class="text-center text-caption">TUE</v-col>
            <v-col class="text-center text-caption">WED</v-col>
            <v-col class="text-center text-caption">THU</v-col>
            <v-col class="text-center text-caption">FRI</v-col>
            <v-col class="text-center text-caption indigo--text darken-3">SAT</v-col>
        </v-row>
        <v-row class="dates">
            <v-col v-for="i in getRange(prevDate - prevDay, prevDate)" :key="'prev'+i" class="day" :style="`color:${$vuetify.theme.currentTheme.faintColor}`">
                {{i}}
            </v-col>
            <v-btn v-for="i in getRange(1, nextDate)" :key="i" @click="dateClick(i)" class="day pa-0" text height="auto" min-width="auto" rounded
                :style="thisDate.getFullYear() == year && thisDate.getMonth() == month && i == day ? `color:${$vuetify.theme.currentTheme.backgroundColor}; background-color:${$vuetify.theme.currentTheme.mainColor}` : ``">
                {{i}}
            </v-btn>
            <v-col v-for="i in getRange(1, (6 - nextDay == 0 ? 0 : 6 - nextDay))" :key="'next'+i" class="day" :style="`color:${$vuetify.theme.currentTheme.faintColor}`">
                {{i}}
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default{
        name: "CalenderComponent",
        data(){
            return{
                thisDate: new Date(this.year, this.month, 1)
            }
        },
        props:{
            year:{
                default: new Date().getFullYear(),
            },
            month:{
                default: new Date().getMonth().toString().padStart(2, '0'),
            },
            day:{
                default: new Date().getDate().toString().padStart(2, '0')
            }
        },
        methods:{
            prevMonth(){ // 이전달 달력
                this.thisDate = new Date(this.thisDate.getFullYear(), this.thisDate.getMonth()-1,1)
            },
            nextMonth(){ // 다음달 달력
                this.thisDate = new Date(this.thisDate.getFullYear(), this.thisDate.getMonth() +1, 1)
            },
            getRange(start, end){ // 날짜 출력할 범위 설정
                return new Array(end - start+1).fill(start).map((n, i) => n+i)
            },
            dateClick(date){
                this.$emit('update:year', this.thisDate.getFullYear())
                this.$emit('update:month', this.thisDate.getMonth())
                this.$emit('update:day', date)
                console.log(this.thisDate.getFullYear(),this.thisDate.getMonth()+1,date)
            }
        },
        computed:{
            printMonth(){ // 연월 출력
                return this.thisDate.getFullYear()+"."+(this.thisDate.getMonth() + 1).toString().padStart(2, '0')
            },
            prevDate(){ // 이전달 일수(마지막일자)
                return new Date(this.thisDate.getFullYear(), this.thisDate.getMonth(), 0).getDate()
            },
            prevDay(){ // 이전달 마지막날의 요일(일 - 0, 월 - 1...)
                return new Date(this.thisDate.getFullYear(), this.thisDate.getMonth(), 0).getDay()
            },
            nextDate(){ // 이번달 일수(마지막일자) 다음달 출력할 일자 계산에 사용
                return new Date(this.thisDate.getFullYear(), this.thisDate.getMonth()+1, 0).getDate()
            },
            nextDay(){ // 이번달 마지막일의 요일
                return new Date(this.thisDate.getFullYear(), this.thisDate.getMonth()+1, 0).getDay()
            },
            
        }
    }
</script>

<style scoped>
    .day{
        display:flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 7) !important;
        flex-basis:revert !important;
        flex-grow: 0;
        text-align:center;
        font-family: "NotoSansR";
        padding:10px !important;
        font-size:15px;
    }
</style>