<template>
    <v-row class="flex-column">
        <v-col class="d-flex align-start">
            <span :style="titleColor ? `color:${titleColor}` : `color:${this.$vuetify.theme.currentTheme.popularUseColor}` ">{{ title }}</span>
            <v-icon v-if="isRequired" style="font-size:xx-small" color="red darken-2">mdi-asterisk</v-icon>
        </v-col>
        <v-col>
            <v-row>
                <slot name="prependSlot"/>
                <v-col v-if="type == 'textArea'">
                    <v-textarea
                        class="pt-0"
                        :placeholder="placeholder"
                        :rules="rules"
                        v-model="ipnut"
                        @input="vModelReturn"
                        :error-messages="errorMessages" :success-messages="successMessages"
                    ></v-textarea>
                </v-col>
                <v-col v-else>
                    <v-text-field class="ma-0 pa-0"  :placeholder="placeholder" v-model="ipnut" :rules="rules" :type="type" @input="vModelReturn" :disabled="disabled"
                        :error-messages="errorMessages" :success-messages="successMessages">
                        <template v-slot:[setSlot]>
                            <slot name="innerSlot"/>
                        </template>
                    </v-text-field>
                </v-col>
                <slot name="appendSlot" />
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'CommonTextField',
    data(){
        return{
            ipnut: ''
        }
    },
    props: {
        title:{ // 입력란 제목
            default: '제목',
            required: true
        },
        isRequired:{ // 필수입력사항인지
            default: false,
            type: Boolean
        },
        placeholder:{ // 플레이스홀더
            default: ''
        }, 
        vModel:{ // v-model
            default: ''
        },
        rules:{ // 입력 규칙
            default: function () {
                return []
            },
            type:Array,
            validator: function (value) { // props 유효성검사
                // 값이 항상 아래 세 개의 문자열 중 하나여야 합니다. 
                return Array.isArray(value)
            }
        },
        type:{ // input 타입
            default: 'text'
        },
        setSlot:{ // slots
            default: ''
        },
        errorMessages:{ // 에러메세지
            default: ''
        },
        successMessages:{ // 성공메세지
            default: ''
        },
        disabled: {
            default: false,
            type: Boolean
        },
        titleColor: {
            default: ''
        }
    },
    methods:{
        vModelReturn(){
            this.$emit('update:vModel', this.ipnut)
        }
    },
    watch: {
        vModel(val) {
            this.ipnut = val
        }
    }
}
</script>

<style scoped>
::v-deep .v-input__append-outer {
    display:block;
    margin-top : 8px;
}
::v-deep .v-label {
    max-width: 100%;
}

::v-deep .v-input--is-disabled input{
    color: black;
}
</style>