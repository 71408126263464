<template>
  <v-btn icon @click="back" :ripple="false">
      <v-icon :color="additionalBtnColor">
        mdi-arrow-left
      </v-icon>
    </v-btn>
</template>

<script>

export default {
  name: 'Back',
  data() {
    return {}
  },
  props:{
    additionalBtnColor:{
        type:String
    },
  },
  created() {
  },
  methods: {
    back() {
      if (this.$route.path === '/cashsave') {
        this.$router.push('/home')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>