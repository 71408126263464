<template>
    <v-btn icon :ripple="false" @click="$function.movePage($pages.CASH.name)">
        <v-icon :color="additionalBtnColor">
            mdi-alpha-c-circle-outline
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: 'Cash',
    props:{
        additionalBtnColor:{
            type:String
        },
    }
}
</script>