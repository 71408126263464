import axios from '../axios'

export async function checkEvse(evseNum){ // 검색한 충전기 번호가 있는지 사용가능한지
    const res = await axios.post('/nonMember/checkEvse',{evseNum:evseNum})
    return res
}

export async function creditCardRegi(sendData){ // 비회원 결제카드 등록
    const res = await axios.post('/nonMember/creditCardRegi', {sendData:sendData})
    return res
}

export async function startChargingRequest(evse_id, card_id, chargeAmount){ // 비회원 충전 시작 요청
    const res = await axios.post('/nonMember/startChargingRequest', {evse_id:evse_id, card_id: card_id, chargeAmount:chargeAmount})
    return res
}

export async function getTransactionInfo(transaction_id){ // 트랜잭션 정보 가져오기
    const res = await axios.post('/nonMember/getTransactionInfo', {transaction_id: transaction_id})
    return res
}

export async function preparingCancel(transaction_id){ // 충전 준비중 취소
    const res = await axios.post('/nonMember/preparingCancel', {transaction_id:transaction_id})
    return res
}

export async function chargingCancel(transaction_id){ // 비회원 충전중 충전종료
    const res = await axios.post('/nonMember/chargingCancel', {transaction_id: transaction_id})
    return res
}

export async function getWaitLimitTime(evse_id){ // 타임아웃 시간 가져오기
    const res = await axios.post('/nonMember/getWaitLimitTime', {evse_id: evse_id})
    return res
}

export async function deleteCard(creditCard_id){ // 
    const res = await axios.post('/nonMember/deleteCard', {creditCard_id: creditCard_id})
    return res
}