<template>
    <v-sheet width="100%" height="100%" class="d-flex flex-column">
        <v-row :style="`background-color:${$vuetify.theme.currentTheme.subBackgroundColor}`" class="pa-2 d-flex justify-space-around flex-grow-0">
            <v-btn outlined color="popularUseColor" rounded small :style="`background-color:${$vuetify.theme.currentTheme.backgroundColor}`" @click="setUnitAmount(10)">+{{$t('NumberKeypod.10')}}</v-btn>
            <v-btn outlined color="popularUseColor" rounded small :style="`background-color:${$vuetify.theme.currentTheme.backgroundColor}`" @click="setUnitAmount(50)">+{{$t('NumberKeypod.50')}}</v-btn>
            <v-btn outlined color="popularUseColor" rounded small :style="`background-color:${$vuetify.theme.currentTheme.backgroundColor}`" @click="setUnitAmount(100)">+{{$t('NumberKeypod.100')}}</v-btn>
            <v-btn v-if="inputLimit != 0" outlined color="popularUseColor" rounded small :style="`background-color:${$vuetify.theme.currentTheme.backgroundColor}`" @click="setUnitAmount('all')">{{$t('NumberKeypod.full')}}</v-btn>
            <v-btn v-else outlined color="popularUseColor" rounded small :style="`background-color:${$vuetify.theme.currentTheme.backgroundColor}`" @click="setUnitAmount(200)">+{{$t('NumberKeypod.200')}}</v-btn>
        </v-row>
        <v-container class="flex-grow-1 d-flex flex-column">
            <v-row class="align-center">
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('1')">1</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('2')">2</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('3')">3</v-btn></v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('4')">4</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('5')">5</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('6')">6</v-btn></v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('7')">7</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('8')">8</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('9')">9</v-btn></v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('00')">00</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('0')">0</v-btn></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('dot')">.</v-btn></v-col>
            </v-row>
            <v-row class="align-center">
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('clear')">C</v-btn></v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4"><v-btn class="text-h3 font-weight-bold" text style="width:100%; height:100%" @click="setNumbetAmount('delete')"><v-icon size="60" color="emphasisColor">mdi-arrow-left</v-icon></v-btn></v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
export default {
    name: 'NumberKeypad',
    data(){
        return{
            input:0
        }
    },
    props:{
        vModel: { 
            default:0,
            required: true
        },
        inputLimit: {
            default: 0,
            type: Number
        }
    },
    methods:{
        setUnitAmount(amount){
            if (this.input.toString().indexOf('.') > -1) {
                const slicedInputValue = this.input.split('.')
                if (amount == 'all') {
                    this.input = this.inputLimit
                } else {
                    if (this.inputLimit > 0) {
                        if (Number(slicedInputValue[0]) + amount + `.${slicedInputValue[1]}` > this.inputLimit) {
                            this.input = this.inputLimit
                        } else {
                            this.input = Number(slicedInputValue[0]) + amount + `.${slicedInputValue[1]}`
                        }
                    } else {
                        this.input = Number(slicedInputValue[0]) + amount + `.${slicedInputValue[1]}`
                    }
                }
            } else {
                this.input = amount == 'all' ? this.input = this.inputLimit : this.input = this.input + amount
            }
        },
        setNumbetAmount(amount){
            if (amount === 'dot') { // 소수점 추가 버튼
                if (this.input.toString().indexOf('.') > -1) { // 이미 소수점 사용중이면, 추가하지 않고 기존 값을 return
                    return
                } else {
                    this.input = this.input + '.'
                }
            }
            else if (amount === 'clear') { // 전체 지우기
                this.input = 0
            }
            else if (amount == 'delete') { // 하나만 지우기
                if (this.input.toString().indexOf('.') > -1) {
                    if (this.input.length > 1) {
                        this.input = this.input.slice(0, -1)
                    } else {
                        this.input = 0
                    }
                } else {
                    if(this.input.toString().length > 1){ // 충전금액 자리수가 1보다 클 경우 맨 뒷숫자 지우기
                        this.input = Number(this.input.toString().slice(0, -1))
                    }else if(this.input.toString().length == 1){ // 자리수가 1자리일 경우 0
                        this.input = 0
                    }
                }
            } else {
                if (this.input.toString().indexOf('.') > -1) {
                    const slicedInputValue = this.input.split('.')
                    if (slicedInputValue[1].length === 2) {
                        return
                    } else {
                        this.input += amount
                    }
                } else {
                    if (this.input == 0 && amount != '00') {
                        this.input = Number(amount)
                    } else {
                        this.input = Number(''.concat(this.input.toString(), amount));
                    }
                }
            }
        },
        vModelReturn(){
            this.$emit('update:vModel', this.input)
        }
    },
    watch:{
        input(val){
            if(val >= this.inputLimit && this.inputLimit != 0){ // 보유캐시 보다 높은 충전금액 설정을 못하도록
                this.input = this.inputLimit
            }
            if(val == ''){ // 아무것도 입력이 안되어있을경우에는 0
                this.input = 0
            }
            this.vModelReturn()
        },
    }
}
</script>

<style scoped>
.card{
    width:320px;
    height:170px;
    cursor:pointer;
}
</style>