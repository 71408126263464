import { make } from 'vuex-pathify'

// app/ 경로 아래에 사용할 상태변수들 정의
const state = {
    region: ['서울특별시', '부산광역시', '대구광역시', '인천광역시', '광주광역시', '대전광역시', '울산광역시', '세종특별자치시', '경기도', '강원도', '충청북도', '충청남도', '전라북도', '전라남도', '경상북도', '경상남도', '제주특별자치도'], // 지역
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

