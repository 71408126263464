<template>
    <v-btn class="text-h6 font-weight-bold" style="color:#fff; box-sizing:border-box !important" bottom x-large block 
        color="mainColor" :disabled="disabled" :type="type" :absolute="absolute" :outlined="outlined" >
        <slot />
    </v-btn>
</template>

<script>
export default {
    name: 'SubmitButton',
    props:{
        disabled:{
            default: false
        },
        type:{
            default: 'submit'
        },
        absolute:{
            default: true
        },
        outlined:{
            default:false
        },
    }
}
</script>