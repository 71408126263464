import axios from '../axios'
/**
 * REQUEST FORM
 * /{controllerName}/{function}
 */
export async function getBoardList(boardType, page = 1, limit = '') {
    const res = await axios.post('/appBoard/getBoardList', { boardType: boardType, limit : limit, page : page })
    return res
}

export async function getBoardDetail(id) { 
    const res = await axios.post('/appBoard/getBoardDetail', {_id:id})
    return res
}

export async function getPopup(popUpArr) { 
    const res = await axios.post('/appBoard/getPopup', {popUpArr : popUpArr})
    return res
}

export async function getPush( page = 1, limit = '') { 
    const res = await axios.post('/appBoard/getPush', { page: page, limit: limit})
    return res
}

export async function readPush(_id) { 
    const res = await axios.post('/appBoard/readPush', { _id: _id })
    return res
}

export async function pushDetail(_id) {
    const res = await axios.post('/appBoard/pushDetail', { _id: _id })
    return res
}