<template>
    <v-bottom-sheet persistent v-model="sheet" transition="scroll-y-transition">
        <v-sheet
            :height="heightSet ? bottomHeight : ''"
            style="position:fixed; bottom:0; left:0;"
            :style="overFlowY ? 'overflow-y:auto;' : ''"
            width="100vw"
            class=" rounded-t-xl  py-2 "
            :class="[!paddingDel ? 'px-4' : '', flexGrow ? ' flex-grow-1 d-flex flex-column' : '']"
            flat tile>
                <div class="d-flex justify-space-between"  :class="[paddingDel ? 'px-4' : '', titleBold ? 'py-2' : '']" >
                    <v-card-title class="pa-0" :class=" titleBold ? 'text-h5  font-weight-bold ' : ''" >{{ title }}</v-card-title>
                    <v-btn icon small @click="closePopup" class="align-center"><v-icon>mdi-window-close</v-icon></v-btn>
                </div>
                <v-divider :class="linePadding ? 'my-2' : ''"></v-divider>

                <slot />
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
export default {
    name: 'BottomPopup',
    data(){
        return{
            sheet: false,
            bottomHeight : 0
        }
    },
    props:{
        isShow:{
            default: false,
            type: Boolean
        },
        title:{
            default: '제목',
            required: true
        },
        heightSet : {
            default : false
        },
        overFlowY : {
            default : false
        },
        flexGrow : {
            default : false
        },
        paddingDel : {
            default : false
        },
        titleBold : {
            default : false
        },
        linePadding : {
            default : true
        }
    },
    methods:{
        closePopup(){ // 팝업을 닫을 때 v-model 토글 및 부모에게도 전달
            this.sheet = !this.sheet
            this.$emit('update:isShow', this.sheet)
        }
    },
    watch:{
        isShow:{
            handler(val){ // 부모로 전달받은 값을 내부 data에 전달
                this.sheet = val
            },
            immediate: true
        }
    },
    mounted() {
        // this.bottomHeight = this.heightSet ? window.screen.height - document.querySelector('.v-toolbar__content').clientHeight : ''
        this.bottomHeight = this.heightSet ? '95%' : ''
    }
}
</script>
