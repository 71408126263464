import kebabCase from 'lodash.kebabcase'
import { leadingSlash, trailingSlash } from './helpers'
import { PAGES } from './pageSettings'

export function abort (code = 404) {
  return {
    name: 'FourOfFour',
    path: '*',
    component: () => error(code),
  }
}

export function error (code = 404) {
  return import('@/views/Error.vue')
}

export function layout (layout = 'Default', children, path = '') {
  const dir = kebabCase(layout)
  
  return {
    children,
    component: () => import(`@/layouts/${dir}/Index`),
    path,
  }
}

export function redirect (path = '*', rhandler) {
  if (typeof path === 'function') {
    rhandler = path
    path = '*'
  }

  return {
    path,
    redirect: to => {
      const rpath = rhandler(to)
      const url = rpath !== ''
        ? leadingSlash(trailingSlash(rpath))
        : rpath

        return `/${url}`
    },
  }
}

export function route (routeName, componentName, component, path = '') {
  component = Object(component) === component
    ? component
    : { default: componentName.replace(' ', '') }
  const components = {}

  for (const [key, value] of Object.entries(component)) {
    components[key] = () => import(`@/views/${value}`)
  }
  
  return {
    name: routeName,
    components,
    path
  }
}

export function autoRoute (){ // pageSettings에 등록된 페이지 자동 라우팅

  let pages = []

  Object.entries(PAGES).forEach(([key, val]) => {
    const routeName = val.name
    const file = val.file
    const routePath = val.path
    
    pages.push(route(routeName, file, null, routePath))
  })
  return layout('Default', pages)
}
