import axios from '../axios'
/**
 * REQUEST FORM
 * /{controllerName}/{function}
 */
export async function creaditCardRegi(sendData) {
    const res = await axios.post('/creditCard/cardRegi', { sendData: sendData })
    return res
}

export async function getList() {
    const res = await axios.post('/creditCard/getList')
    return res
}

export async function deleteCard(card_id) { 
    const res = await axios.post('/creditCard/deleteCard', { card_id: card_id })
    return res
}

export async function changeRepreCard(card_id) { 
    const res = await axios.post('/creditCard/changeRepreCard', { card_id: card_id })
    return res
}

export async function getCardInfo(cardBin) { 
    console.log(cardBin)
    const res = await axios.post('/creditCard/getCardInfo', { cardBin: cardBin })
    return res
}