<template>
    <v-btn icon :ripple="false" @click="$function.movePage($pages.PUSH_LIST.name)">
        <v-badge 
            overlap
            :color="pushCnt > 0 ? 'amber darken-3' : 'transparent'"
            :content="pushCnt > 0 ? pushCnt : '' "
            offset-y="10"
        >
            <v-icon :color="additionalBtnColor">
            mdi-bell-outline
            </v-icon>
        </v-badge>
    </v-btn>
</template>

<script>
import EventBus from '@/util/eventBus'

export default {
    name: 'Bell',
    data() {
        return {
            pushCnt : null
        }
    },
    props:{
        additionalBtnColor:{
            type:String
        },
    },
    created() {
        EventBus.$on('pushCnt', (res) => {
            this.pushCnt = res
        })
    }
}
</script>