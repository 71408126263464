<template>
    <v-card class="card d-flex flex-column justify-center  pa-3" color="success">
        <v-card-text class="text-subtitle-1 text-center" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}`">{{ cardNumber }}</v-card-text>
        <v-overlay
            v-if="cardInfo"
            absolute
            :value="cardInfo.status !== $constant.IDTOKENS.STATUS.ACCECPTED.code"
            color="emphasisColor"
            opacity="0.7"
        >
            <p class="mb-0">{{$t('MemeberCard.expiredMsg')}}</p>
        </v-overlay>
    </v-card>
</template>

<script>
export default {
    name: 'MemberCard',
    props:{
        cardInfo: Object,
    },
    computed:{
        cardNumber(){
            return this.cardInfo ? this.cardInfo.idToken.idToken.replace(/^(.{4})(.{4})(.{4})(.*)$/, "$1 $2 $3 $4") : ''
        },
    },
}
</script>

<style scoped>
.card{
    width:320px;
    height:170px;
    cursor:pointer;
}
</style>