<template>
    <v-slide-item>
        <!-- d-flex flex-column justify-space-between -->
        <v-card
            class="ma-2 pa-3 rounded-xl " 
            width="200"
            :to="{name: 'PlaceInfo', params:{place_id:place_id}}"
        >
            <v-card-title class="text-subtitle-1 pa-0">{{ placeInfo.placeName }}</v-card-title>
            <v-row class="caption" v-if="coordinate.lat > 0 && coordinate.lng > 0">
                <v-col :style="`color:${$vuetify.theme.currentTheme.popularUseColor}`" cols="2">{{$t('RecentlyUsePlace.distance')}}</v-col>
                <v-col>{{ placeInfo.distance }}km</v-col>
            </v-row>
            <v-row class="caption">
                <v-col :style="`color:${$vuetify.theme.currentTheme.popularUseColor}`" cols="2" >{{$t('RecentlyUsePlace.address')}}</v-col>
                <v-col cols="10" class="px-1">{{ placeInfo.address }}</v-col>
            </v-row>
            <v-row>
                <v-chip-group>
                    <v-chip
                        v-for="(connector, key) in placeInfo.connector"
                        :key="key"
                        disabled small
                        color="#f5f5f5"
                        text-color="popularUseColor"
                        style="opacity:1"
                        class="my-0 mr-1"
                    >
                    #{{ $function.codeToName($constant.EVSE.CONNECTOR,connector) }}
                    </v-chip>
                </v-chip-group>
            </v-row>
            <v-row v-if="placeInfo.placeStatus !== $constant.PLACE.STATUS.NORMAL.code" class="text-caption" :style="`color:${$vuetify.theme.currentTheme.error}`">{{ $function.codeToName($constant.PLACE.STATUS, placeInfo.placeStatus) }}</v-row>
            <v-row v-else class="text-caption" :style="`color:${placeInfo.usableEvse > 0 ? $vuetify.theme.currentTheme.success : $vuetify.theme.currentTheme.faintColor}`">{{ $tc('RecentlyUsePlace.unitAvailableCnt', placeInfo.usableEvse, {cnt: placeInfo.usableEvse}) }}</v-row>
        </v-card>
    </v-slide-item>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
    name:'RecentlyUsePlace',
    props:{
        placeInfo:{
            type: Object,
            required: true
        },
        place_id:{
            required:true
        }
    },
    computed: {
        coordinate: get('app/coordinate'),
    },
}
</script>

<style scoped>
    .row{
        margin:0;
    }
</style>