<template>
    <v-container class="pa-5" >
        <v-card :color="containerColor" :elevation="isElevation ? 1 : 0" :class="`${isPadding  ? 'pa-5' : ''} ${isRadius ? 'rounded-xl' : ''}`" height="100%">
            <slot></slot>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'CommonContainer',
    props: {
        containerColor: { // 컴포너틑 배경색
            default: 'backgroundColor'
        },
        isElevation:{ // 그림자
            default: true
        },
        isRadius:{ // 둥근 모서리
            default: true
        },
        isPadding:{ // padding
            default: true
        },
    }
}
</script>