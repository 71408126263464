// import axios from "axios";
import axios from '../axios'
/**
 * REQUEST FORM
 * /api/{controllerName}/{function}
 */
export async function currentMonthChargingInfo(){ // 이번달 충전량 및 충전 금액
    const res = await axios.post('/charge/currentMonthChargingInfo')
    return res
}

export async function recentlyUsePlace(lat, lng){ // 최근 이용한 충전소
    const res = await axios.post('/charge/recentlyUsePlace', { lat: lat, lng: lng })
    return res
}

export async function searchEvseNum(evseNum){ // evse 번호 검색
    const res = await axios.post('/charge/searchEvseNum', {evseNum:evseNum})
    return res
}

export async function searchPlace(searchInfo, lat, lng){ // 충전소 찾기
    const res = await axios.post('/charge/searchPlace', {searchInfo: searchInfo, lat: lat, lng:lng})
    return res
}

export async function placeInfo(place_id, lat, lng){ // 충전소 정보
    const res = await axios.post('/charge/placeInfo', {place_id:place_id, lat:lat, lng:lng})
    return res
}

export async function evseInfo(evse_id){ // evse 정보
    const res = await axios.post('/charge/evseInfo', {evse_id: evse_id})
    return res
}

export async function startChargingRequest(evse_id, authType, chargeAmount, card_id){ // 충전 요청
    const res = await axios.post('/charge/startChargingRequest', {evse_id:evse_id, authType:authType, chargeAmount:chargeAmount, card_id: card_id})
    return res
}

export async function getTransactionInfo(transaction_id){ // 충전중 정보 가져오기
    const res = await axios.post('/charge/getTransactionInfo', {transaction_id:transaction_id})
    return res
}

export async function preparingCancel(transaction_id){ // 충전 준비중 취소
    const res = await axios.post('/charge/preparingCancel', {transaction_id:transaction_id})
    return res
}

export async function chargingCancel(transaction_id){ // 충전대기중 취소
    const res = await axios.post('/charge/chargingCancel', {transaction_id: transaction_id})
    return res
}

export async function chargeHistory(start, end){ // 충전 내역
    const res = await axios.post('/charge/chargeHistory', {start:start, end:end})
    return res
}

export async function getEvseStatusByTrancationId(transaction_id){ // 트랜잭션 _id로 evse 상태 가져오기
    const res = await axios.post('/charge/getEvseStatusByTrancationId', {transaction_id: transaction_id})
    return res
}

export async function isChargingCheck(){ // 충전중인지 홈에서 체크
    const res = await axios.post('/charge/isChargingCheck')
    return res
}

export async function getWaitLimitTime(evse_id){ // 충전대기중 제한 시간
    const res = await axios.post('/charge/getWaitLimitTime',{evse_id:evse_id})
    return res
}

export async function getNearPlace(lat, lon, searchInfo) {  // 충전소(지도) 찾기
    const res = await axios.post('/charge/getNearPlace',{lat:lat, lon:lon, searchInfo : searchInfo})
    return res
}

export async function simplePlaceInfo(place_id, lat, lng){ // 충전소 정보
    const res = await axios.post('/charge/simplePlaceInfo', {place_id:place_id, lat:lat, lng:lng})
    return res
}

export async function favoriteCheck(place_id) { // 즐겨찾는 충전소 여부 확인
    const res = await axios.post('/charge/favoriteCheck', {place_id : place_id})
    return res
}

export async function setFavoritePlace(place_id) { // 즐겨찾는 충전소 설정
    const res = await axios.post('/charge/setFavoritePlace', { place_id: place_id })
    return res
}

export async function removeFavorite(place_id) {    // 즐겨찾는 충전소 제거
    const res = await axios.post('/charge/removeFavorite', { place_id: place_id })
    return res
}

export async function favoriteList() { // 즐겨찾는 충전소 리스트
    const res = await axios.post('/charge/favoriteList')
    return res
}

export async function recentlyChargingInfo() { // 최근 충전내역
    const res = await axios.post('/charge/recentlyChargingInfo')
    return res
}