<template>
    <v-carousel v-model="slide" hide-delimiter-background height="250" 
        :show-arrows="false" delimiter-icon="mdi-circle-medium">
        <v-carousel-item
            v-for="(cardInfo, index) in cardList" :key="index"
        >   
            <v-row class="flex-nowrap">
                <v-col cols="1" style="transform:scaleY(0.9)" >
                    <div v-show="slide != 0"><credit-card></credit-card></div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="9">
                    <credit-card :cardInfo="cardInfo" ></credit-card>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" style="transform:scaleY(0.9)">
                    <div v-show="slide != (cardList.length - 1)"><credit-card></credit-card></div>
                </v-col>
            </v-row>
        </v-carousel-item>
    </v-carousel>
</template>

<script>

export default {
    name: 'CardList',
    data(){
        return{
            slide:null,
        }
    },
    props:{
        cardList:Array 
    },
    watch : {
        slide(val) {
            this.$emit('update:currentCardId', this.cardList[val]._id)
        }
    },
    mounted() {
        this.slide = 0
    }
}
</script>

<style>
    .v-carousel__controls__item .v-icon{
        opacity: 1 !important;
    }
    .v-carousel__controls__item{
        color: #78909C !important;
        margin: 0 !important;
    }
    .v-carousel__controls__item::before{
        display: none !important;
    }
    .v-carousel__controls__item.v-item--active{
        color: #2979FF !important;
    }
</style>