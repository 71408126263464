<template>
    <v-dialog
        v-model="dialog"
        width="100%"
        scrollable
        persistent
        :retain-focus="false"
        :hide-overlay="hideOverlay"
    >
        <v-card tile flats class="px-5">
            <v-card-title class="text-h5 px-0 py-5">
                {{popupContents.title}}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text  style="height: 50vh;"  class="py-5 black--text"  >
                <v-img v-for="(item, index) in popupContents.img" :key="index" :src="item" contain></v-img>
                <div v-html="popupContents.contents"></div>
            </v-card-text>
        
            <v-divider></v-divider>

            <v-card-actions class="justify-space-between">
                <v-btn
                    :color="$vuetify.theme.currentTheme.mainColor"
                    text
                    @click="closeDialog"
                >
                    {{$t('Popup.close')}}
                </v-btn>
                <v-btn
                    :color="$vuetify.theme.currentTheme.mainColor"
                    text
                    @click="dontOpen"
                >
                    {{$t('Popup.notOpenToday')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Popup',
    data() {
        return {
            dialog : false
        }
    },
    props : {
        isShow:{
            default: false,
            type: Boolean
        },
        popupContents : {
            type: Object
        },
        hideOverlay : {
            default: false,
            type: Boolean
        }, 
        userId : {
            default :'',
            type : String
        }
    },
    watch:{
        isShow:{
            handler(val){ // 부모로 전달받은 값을 내부 data에 전달
                this.dialog = val
            },
            immediate: true
        }
    },
    methods : {
        async dontOpen() {
            let arr = []
            let popupArr = []

            let date = new Date()
            date.setHours(24,0,0,0)
            
            if(localStorage.getItem("popups") == null) {
                arr.push({
                    _id : this.userId,
                    seqNo : this.popupContents.seqNo,
                    expire : date.getTime()
                }) 
            } else {
                popupArr = JSON.parse(localStorage.getItem("popups"))

                if(!Array.isArray(popupArr)) {
                    popupArr = [popupArr]
                }
                const userArr =  popupArr.filter((arr) => {
                    return arr._id == this.userId
                })[0]

                arr = popupArr
                if(userArr == null) {
                    arr.push({
                        _id : this.userId,
                        seqNo : this.popupContents.seqNo,
                        expire : date.getTime()
                    })
                } else {
                    arr.forEach(arr => {
                        if(arr._id == this.userId) {
                            arr.seqNo = [arr.seqNo, this.popupContents.seqNo]
                        }
                    })
                }
            }
            localStorage.setItem("popups", JSON.stringify(arr));
            this.closeDialog()
        },
        closeDialog() {
            this.dialog = false
            if(document.getElementsByClassName('v-dialog--active').length == 1) {
                document.getElementsByTagName('html')[0].className = ""
                
            } else {
                this.$nextTick(() => {
                    document.getElementsByTagName('html')[0].className = "overflow-y-hidden"
                })
            }
        }
    }
} 
</script>

<style scoped>
::v-deep .v-card__text  p {
    margin-bottom: 8px !important;
}
</style>

<style lang="sass" scoped>
::-webkit-scrollbar
    width: 8px
    height: 8px 
    border: 3px solid #ffffff

::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment
    display: block
    height: 0px

::-webkit-scrollbar-track
    background: #ffffff
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.1)

::-webkit-scrollbar-thumb
    height: 50px
    width: 50px 
    background: rgba(0,0,0,.1)
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.4)

</style>
