<template>
    <v-card :to="to" :href="href" :target="target"  :class="[isMargin ? 'mb-2' : '', isPadding ? 'pa-4' : '' ]" flat tile :style="`border: 1px solid ${$vuetify.theme.currentTheme.faintColor} !important`">
        <slot/>
    </v-card>
</template>

<script>
export default {
    name: 'ListViewItem',
    props:{
        to:{
            default: ''
        },
        isMargin : {
            default : true
        },
        href: { 
            default: ''
        },
        target : {
            default : ''
        },
        isPadding: {
            default : true
        }
    }
}
</script>