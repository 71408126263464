<template>
      <router-view />
</template>

<script>
  import './styles/overrides.sass'
  import { sync } from 'vuex-pathify'
  export default {
    name: 'App',
    metaInfo: {
      title: 'KLINELEX',
      titleTemplate: '%s | MOBILE APP',
      htmlAttrs: { lang: 'ko' },
      meta: [
        { charset: 'utf-8'},
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    data: () => ({
      //
    }),
    async beforeCreate(){
      const constants = await this.$function.vueInit()
      for(let key in constants){ // backend에 저장된 constant를 받아서 불러옴(back, front 공용으로 쓸 상수)
        this.$constant[key] = constants[key]
      }
    },
    created() {
      const isMobile = this.$function.isMobile()

      if(isMobile) {
        window.MovePage = this.MovePage
      }
      this.lang = this.$i18n.locale
    },
    methods : {
      MovePage(param) {
        console.log('MovePage')
        let url = ''
        switch (param) {
          case 'PUSH':
            url = this.$pages.PUSH_LIST.name
            break;
          default:
            break;
        }
        this.$function.movePage(url)
      }
    },
    computed: {
      lang: sync('app/lang'),
    },
  }
</script>

<style>
/** 버튼텍스트 영문 자동으로 대문자화 방지 */
  .v-btn__content{
    text-transform: initial;
  }

  input::placeholder  {
    white-space:pre-line;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .clickable {
    cursor: pointer;
    user-select: none;
  }

</style>