<template>
    <v-list width="100%" :height="height" class="pa-0" color="subBackgroundColor">
        <v-list-item-group :class="isPadding ? 'pa-2 pb-15' : ''">
            <slot />
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: 'ListViewContainer',
    props : {
        isPadding: {
            default : true
        },
        height: {
            default : '100%'
        }
    }
}
</script>