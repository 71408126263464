import axios from '../axios'
/**
 * REQUEST FORM
 * /api/{controllerName}/{function}
 */
export async function idDuplicateCheck(id){ // 중복확인
    const res = await axios.post('/user/idDuplicateCheck', {userName:id})
    return res
}

export async function signUp(userInfo){ // 회원가입
    const res = await axios.post('/user/signUp', userInfo)
    return res
}

export async function login(loginInfo){
    const res = await axios.post('/user/login', loginInfo)
    return res
}

export async function logout(){
    const res = await axios.post('/user/logout')
    return res
}

export async function getInfo() { // 유저 기본정보 요청
    const res = await axios.post('/user/getInfo')
    return res
}

export async function changeInfo(sendData) { // 개인정보변경
    const res = await axios.post('/user/changeInfo', sendData)
    return res
}

export async function dontOpenPopup(arr) { // 팝업 하루동안 열지않기
    const res = await axios.post('/user/dontOpenPopup', {arr : arr})
    return res
}

export async function findInfo(sendData) { 
    const res = await axios.post('/user/findInfo', sendData)
    return res
}

export async function resetPwd(sendData) { 
    const res = await axios.post('/user/resetPwd', sendData)
    return res
}

export async function kakaoJoin(sendData) { 
    const res = await axios.post('/user/kakaoJoin', sendData)
    return res
}

export async function autoLogin(sendData) { 
    const res = await axios.post('/user/autoLogin', sendData)
    return res
}

export async function firebaseToken(token) { 
    console.log('api firebaseToken 11' )
    const res = await axios.post('/user/firebaseToken', { token: token })
    console.log(JSON.stringify(res))
    return res
}

export async function getMemberCardInfo(){ // 유저 회원카드 정보 가져오기
    const res = await axios.post('/user/getMemberCardInfo')
    return res
}

export async function memberCardRegi(cardNo, password){ // 유저 회원카드 등록하기
    const res = await axios.post('/user/memberCardRegi',{cardNo: cardNo, password: password})
    return res
}

export async function memberCardDelete(idCard_id){ // 유저 회원카드 삭제
    const res = await axios.post('/user/memberCardDelete', {idCard_id:idCard_id})
    return res
}

export async function modiCardPwd(idCard_id,password){ // 유저 회원카드 비밀번호 변경
    const res = await axios.post('/user/modiCardPwd', {idCard_id: idCard_id, password:password})
    return res
}

export async function getUserType(){ // 유저 타입 가져오기
    const res = await axios.post('/user/getUserType')
    return res
}

export async function SecessionUser(pwd) { 
    const res = await axios.post('/user/SecessionUser', {pwd : pwd})
    return res 
}