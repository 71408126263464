<template>
    <v-btn icon :ripple="false" @click="$function.movePage($pages.HOME.name)">
        <v-icon :color="additionalBtnColor">
            mdi-home
        </v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: 'Home',
        props:{
            additionalBtnColor:{
                type:String
            },
        }
    }
</script>