<template>
    <v-row class="flex-column">
        <v-col class="d-flex align-start">
            <span :style="`color:${this.$vuetify.theme.currentTheme.popularUseColor}`">{{ title }}</span>
            <v-icon v-if="isRequired" style="font-size:xx-small" color="red darken-2">mdi-asterisk</v-icon>
        </v-col>
        <v-col>
            <v-row>
                <v-col>
                    <v-file-input
                        :accept="accept"
                        :prepend-icon="prependIcon"
                        @change="vModelReturn"
                        @click:clear="removeFiles"
                        v-model="input"
                        :rules="rules"
                        :placeholder="placeholder"
                        :multiple="multi"
                        id="fileInput"
                        
                    ></v-file-input>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'FileInput',
    data(){
        return{
            input: {},
            file : {}
        }
    },
    props: {
        title:{ // 입력란 제목
            default: '제목',
            required: true
        },
        isRequired:{ // 필수입력사항인지
            default: false,
            type: Boolean
        },
        placeholder:{ // 플레이스홀더
            default: ''
        }, 
        vModel:{ // v-model
            default: ''
        },
        rules:{ // 입력 규칙
            default: function () {
                return []
            },
        },
        prependIcon : { // 파일 아이콘
            default : 'mdi-camera'
        },
        accept : {
            default: ''
        },
        multi:{ 
            default: false,
            type: Boolean
        },
    },
    methods:{
        vModelReturn(){
            this.$emit('update:vModel', this.input)
            this.$emit('update')

            this.$nextTick (() => {
                document.querySelector('.v-file-input__text').innerHTML = ''
            })
        },
        removeFiles() {
            this.$emit('change')
        }
    },
    watch: {
        vModel(val) {
            this.input = val
        },
    }
}
</script>