<template>
  <v-app-bar
    id="default-app-bar" app fixed flat
    v-if="layoutOptions.appBarShow"
    :color="layoutOptions.appBarBackground"
  >
    <v-img v-if="layoutOptions.appBarLogo" :src="logoPath" height="100%" contain >
      <v-app-bar-nav-icon v-if="layoutOptions.appBarBtn">
        <component :is="appBarComponent" :additionalBtnColor="layoutOptions.appBarBtnColor"/>
      </v-app-bar-nav-icon>
      <component v-if="layoutOptions.additionalBtn" :is="component" :additionalBtnColor="layoutOptions.appBarBtnColor" style="position:absolute; right: 0px;" />
    </v-img>

    <div v-else>
      <v-app-bar-nav-icon v-if="layoutOptions.appBarBtn">
        <component :is="appBarComponent" :additionalBtnColor="layoutOptions.appBarBtnColor"/>
      </v-app-bar-nav-icon>
      <component v-if="layoutOptions.additionalBtn" :is="component" :additionalBtnColor="layoutOptions.appBarBtnColor" style="position:absolute; right: 18px;" />
    </div>
  </v-app-bar>
</template>

<script>
  import { sync } from 'vuex-pathify'
  export default {
    name: 'AppBar',
    data(){
      return{
        component: null,
        appBarComponent: null,
        logoPath: null
      }
    },
    props:{
      layoutOptions:{
        type:Object
      }
    },
    methods:{
      back(){
        this.$router.go(-1)
      },
      srcSet(val) { 
        this.logoPath = require(`@/assets/${val}`)
      }
    },
    watch : {
      'layoutOptions.additionalBtn' : {
        handler(val) {
          this.component = () => import(`@/components/additionalButton/${val}`)
        },
        immediate: true,
      },
      'layoutOptions.appBarBtn': {
        handler(val) {
          if (val != undefined) { 
            this.appBarComponent = () => import(`@/components/additionalButton/${val}`)
          }
        },
        immediate: true,
      },
      'layoutOptions.appBarLogo': {
        handler(val) { 
          if (val != undefined) {
            this.srcSet(val)
          }
        },
        immediate: true,
      }
    },
    computed: {
      leftMenu: sync('app/leftMenu')
    }
  }
</script>

<style scoped>
/* #default-app-bar{
  
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
} */
</style>