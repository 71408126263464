import axios from '../axios'
/**
 * REQUEST FORM
 * /api/{controllerName}/{function}
 */
export async function fileUpload(formData, type) {
    let url = ''
    if (type == 'single') {
        url = '/file/upFileSingle'
    } else { 
        url = '/file/upFileMulti'
    }
    const res = await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data; charset=utf-8;' } })
    return res
}

export async function fileDown(fileInfo) {
    const res = await axios.post('/file/fileDown', fileInfo , {
        responseType: 'arraybuffer' 
    })

    return res
}
