<template>
    <v-snackbar
      v-model="active"
      width="100%"
      transition="slide-y-transition"
      :top="true"
      :timeout="closeInterval"
      :color="$vuetify.theme.currentTheme[alertConfig.type]"
      @click.native="dismiss">
      <v-icon left>
        {{ printIcon(alertConfig.type) }}
      </v-icon>
      {{ alertConfig.messages }}
    </v-snackbar>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
    name: 'Alert',
    data(){
        return{
          closeInterval: 2000,
          active:false,
          dissmissible: true,
          icon:'information'
        }
    },
    computed: {
        alertConfig: sync('app/alertConfig'),
        printIcon(){
          return(type) => {
            switch(type){
              case 'info':
                return 'mdi-information'
              case 'success':
                return 'mdi-check-circle'
              case 'warning':
                return 'mdi-alert-outline'
              case 'error':
                return 'mdi-alert-circle-outline'
              default:
                return 'mdi-information'
            }
          }
        }
    },
    watch:{
        alertConfig:{
            handler: function(val){
              this.show()
            },
            deep: true
        }
    },
    methods:{
      show(){
        if(this.active){
          this.close()
          this.$nextTick(() => 
            setTimeout(() => this.show(), 50)
          )
          return
        }
        this.alertConfig.isShow = false
        this.active = true
      },
      close(){
        this.active = false
      },
      dismiss(){
        if(this.dissmissible){
          this.close()
        }
      }
    }
}
</script>
