<template>
    <v-card class="card d-flex flex-column justify-center  pa-3  justify-space-between" color="mainColor" elevation="5" v-if="!cardRegi" >
        <v-card-text class="text-right pr-2" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}; `" >{{ cardType }}</v-card-text>
        <v-card-text class="text-subtitle-1 text-center" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}`">{{ cardNumber }}</v-card-text>
        <v-card-text class="d-flex justify-space-between" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}; `">
            <div class="pl-2">{{ cardCompany }}</div>
            <div class="pr-2">{{ cardExpire }}</div>
        </v-card-text>
        <v-overlay
            absolute
            :value="cardExpiration"
            color="emphasisColor"
            opacity="0.7"
        >
            <p class="mb-0">{{$t('CreditCard.expiredMsg')}}</p>
        </v-overlay>
    </v-card>
    <v-card class="card d-flex flex-column justify-center pa-3 justify-space-between" color="popularUseColor" elevation="5" v-else>
        <v-card-text class="text-right" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}; `" > {{ inputCardCompany == '' ? '&nbsp;' : inputCardCompany }} </v-card-text>
        <v-card-text class="text-subtitle-1 text-center" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}`">{{inputCardNo}}</v-card-text>
        <v-card-text class="d-flex justify-space-between" :style="`color:${$vuetify.theme.currentTheme.backgroundColor}; `">
            <div class="pl-2"> {{ inputName }}</div>
            <div class="pr-2"> {{ inputCardExpire }} </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'CreditCard',
    data(){
        return{
            cardExpiration : false
        }
    },
    props:{
        cardInfo: Object,
        cardRegi : {
            default : false
        },
        inputData : Object
    },
    computed:{
        cardNumber() {
            return this.cardInfo ? this.cardInfo.cardNoMasked.replace(/^(.{4})(.{4})(.{4})(.*)$/, "$1 **** **** $4") : ''
        },
        cardExpire(){
            let expireYear = ''
            let expireMonth = ''

            if(this.cardInfo != undefined) {
                const currentDate = new Date()
                let currentYear = parseInt(String(currentDate.getFullYear()).slice(-2))
                let currentMonth = currentDate.getMonth() + 1

                let cardDate = this.$function.unixToDatetime(this.cardInfo.expire) 
                expireYear = cardDate.substr(2, 2);
                expireMonth = cardDate.substr(5, 2);

                if( parseInt(`${expireYear}${('00' + expireMonth).slice(-2)}`) < parseInt(`${currentYear}${('00' + currentMonth).slice(-2)}`) ) {
                    this.expirationChange()
                } 
            }
            return this.cardInfo ? (expireYear + (('00' + expireMonth).slice(-2))).replace(/^([0-9]{0,2})([0-9]{0,2})/g,"$2 / $1") : ''
        },
        cardCompany() {
            return this.cardInfo ? this.cardInfo.cardName : ''
        },
        cardType() {
            return this.cardInfo ? (this.cardInfo.userType == "Personal" ? this.$i18n.t('CreditCard.personal') : this.$i18n.t('CreditCard.corporate')): ' '
        },
        inputCardExpire() {
            const exprieYear =  this.inputData.expireYear ? this.inputData.expireYear : 'YY'
            const expireMonth = this.inputData.expireMonth ? ('00' + this.inputData.expireMonth).slice(-2) : 'MM'

            return `${expireMonth} / ${exprieYear}`
        },
        inputCardNo() {
            let cardNo = ''

            for(var i = 1; i <= 4 ; i++) {
                if(i == 2 || i == 3) {
                    cardNo += (this.inputData[`card_${i}`] ? this.inputData[`card_${i}`].replace(/[0-9]/g, '*')  + ' ' : '')
                    continue
                }   
                cardNo += (this.inputData[`card_${i}`] ? this.inputData[`card_${i}`] + ' ' : '')
            }   
            
            return cardNo
        },
        inputCardCompany() {
            return this.inputData.cardCompany ? this.inputData.cardCompany : ''
        },
        inputName() {  
            return this.inputData.name ? this.inputData.name : ''
        }   
    },
    methods : {
        expirationChange() { 
            this.cardExpiration = true
        }
    },
}
</script>

<style scoped>
.card{
    width:320px;
    height:170px;
    cursor:pointer;
}
</style>