import axios from '../axios'
/**
 * REQUEST FORM
 * /{controllerName}/{function}
 */
export async function myCash(sendData) {
    const res = await axios.post('/cash/myCash', sendData)
    return res
}

export async function saveCash(sendData){
    const res = await axios.post('/cash/saveCash',sendData)
    return res
}

export async function refundCash(amount, reason) {
    const res = await axios.post('/cash/refundCash', { amount: amount, reason: reason })
    return res
}

export async function giftCash(sendData) {
    const res = await axios.post('/cash/giftCash', sendData)
    return res
}
