<template>
    <v-sheet width="100%" class="flex-grow-1 overflow-auto sheetScroll" color="subBackgroundColor"  style="padding-bottom:55px !important;"  v-scroll.self="onScroll"  v-touch="{down: () => offsetTop <= 0 ? refresh() : '', up:() =>  isIntersecting && !isMoreLoading ?  moreList() : ''} ">
        <progress-curcul :isShow.sync="isRefreshLoading"></progress-curcul>
        <list-view-container v-if="itemList.length > 0" :key="refreshKey">
            <v-card tile flat class="pa-0 ma-0" color="transparent">
                <list-view-item  v-for="(item, index) in itemList" :key="index" v-intersect="index == itemList.length-1 ? { handler: onIntersect, options: {root : null, threshold: [1]} } : '' ">
                    <slot v-bind:item="item" ></slot>
                </list-view-item>
            </v-card>
            <progress-curcul  :isShow.sync="isMoreLoading" id="moreLoading"></progress-curcul>
        </list-view-container>

        <list-view-container v-else >
            <slot name="noData" ></slot>
        </list-view-container>
    </v-sheet>
</template>

<script>
export default {
    name : 'ListPullToRefresh',
    props : {
        itemList : {
            type: Array,
            default: function () {
                return []
            },
        },
        getMore : {
            type:Boolean,
            default : true
        },
        isRefresh : {
            type :Boolean,
            default : false
        },
        isMore : {
            type :Boolean,
            default : false
        },
        currnetPage : {
            type : Number,
            default : 1
        }
    },
    watch : {
        getMore(val) {
            this.getMoreList = val
        },
        isRefresh(val) {
            this.isRefreshLoading = val
            if(!val) {
                ++this.refreshKey
            }
        },
        isMore(val) {
            this.isMoreLoading = val
        },
        currnetPage(val) {
            this.page = val
        }
    },
    data() {
        return {
            isRefreshLoading : false,      // 새로고침 로딩
            isMoreLoading : false,         // 아이템 추가 로딩,
            isIntersecting : false, // 리스트영역 맨 아래 아이템
            getMoreList : true,
            offsetTop : 0,          // 리스트영역 맨 위
            page : 1,               // 현재 페이지
            refreshKey : 0
        }
    },
    methods : {
        onIntersect (entries) {
            this.isIntersecting = entries[0].isIntersecting
        },
        onScroll(e) {
            this.offsetTop = e.target.scrollTop
        },
        refresh() {
            if(this.isRefreshLoading) {
                return
            }
            if(this.offsetTop <= 0) {
                this.isRefreshLoading = true       // 새로고침 로딩 시작
                this.page = 1               // 새로고침시 현재 페이지 1페이지로
                this.getMoreList = true
            }

            this.getList()
            this.$emit('update:isRefresh', this.isRefreshLoading)
            this.$emit('update:getMore', this.getMoreList)
        },
        moreList() {
            if(this.isMoreLoading || !this.getMoreList) {
                return 
            }
            this.isMoreLoading = true
            this.page += 1

            setTimeout(() => {
                this.getList()
                this.$emit('update:isMore', this.isMoreLoading)
            }, 1000)
   
            this.$nextTick (() => {
                this.$vuetify.goTo("#moreLoading", {
                    container : '.sheetScroll'
                })
            })
        },
        getList() {
            this.$emit('update:currnetPage', this.page)
            this.$emit('callBack')
        },
    }
}
</script>

<style lang="sass" scoped>
::-webkit-scrollbar
    width: 0px
    background: transparent
</style>