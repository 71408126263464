import { make } from 'vuex-pathify'

// app/ 경로 아래에 사용할 상태변수들 정의
const state = {
  drawer: null,
  alertConfig:{ // alert config
    type: 'info',
    messages: '',
    isShow: false
  },
  coordinate:{
    lat: 3.059991,
    lng: 101.522629
  },
  lang:'ko',
  popup: false,
  naviInfo: null, 
  loadStateNaviScript: -1,
  leftMenu: false,
  userInfo: {
    userName: '',
    name: ''
  }
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  // init: async ({ dispatch }) => {
    
  // },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
