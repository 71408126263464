<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="100%"
    >
        <v-card>
            <v-card-title class="text-h6 px-6 py-4">
                {{ title }}
            </v-card-title>
            <v-card-text class="px-6 py-1" v-html="contents"></v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :color="$vuetify.theme.currentTheme.popularUseColor"
                    text
                    @click="$emit('cancelFun')"
                >
                    {{$t('Confirm.cancel')}}
                </v-btn>
                <v-btn
                    :color="$vuetify.theme.currentTheme.mainColor"
                    text
                    @click="$emit('callbackFun')"
                >
                    {{$t('Confirm.confirm')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Confirm',
    data() {
        return {
            dialog : false
        }
    },
    props:{
        isShow:{
            default: false,
            type: Boolean
        },
        title:{
            default: '제목',
            required: true
        },
        contents: {
            default: "",
            type: String
        }
    },
    watch:{
        isShow:{
            handler(val){ // 부모로 전달받은 값을 내부 data에 전달
                this.dialog = val
            },
            immediate: true
        },
    }
}
</script>
