<template>
  <v-btn icon  :ripple="false" @click="leftMenu = !leftMenu">
    <v-icon :color="additionalBtnColor">
      mdi-menu
    </v-icon>
  </v-btn>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  name: 'LeftMenu',
  data() {
    return {}
  },
  props:{
    additionalBtnColor:{
        type:String
    },
  },
  created() {
  },
  computed: {
    leftMenu: sync('app/leftMenu')
  }
}
</script>