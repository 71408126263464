import Vue from "vue";
import VueRouter from "vue-router";
import { trailingSlash } from "@/util/helpers";
import { abort, autoRoute, route, layout } from "@/util/routes";

Vue.use(VueRouter);
const routes = [autoRoute(), 
{
  path: '/_=_',
  redirect: '/'
},
abort()];

const router = new VueRouter({
  mode: "hash",
  base: `${process.env.BASE_URL}`,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes,
});

// router.beforeEach((to, from, next) => {
//   return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
// })

export default router;
