import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ko from 'vuetify/lib/locale/ko'

Vue.use(Vuetify);

// 맘껏 추가/변경 가능함
const lightTheme = {
  primary: '#00a7e1',
  secondary: '#0076be',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',

  // 일단 컬러 및 배경에 사용
  backgroundColor: '#FFFFFF', // colors.shades.white
  subBackgroundColor: '#F5F5F5', //colors.grey.lighten4
  mainColor: '#00a7e1', // colors.blue.accent3
  emphasisColor: '#000000', // colors.shades.black
  popularUseColor: '#78909C', // colors.blueGrey.lighten1
  faintColor: '#CFD8DC', // colors.blueGrey.lighten4
}

const darkTheme = {
  primary: '#1E88E5',
  secondary: '#0076be',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

export default new Vuetify({
    lang: {
      locales: { ko },
      current: 'ko',
    },
    theme: {
      dark: false, // 기본값
      themes: {
        dark: darkTheme,
        light: lightTheme,
      },
    },
    
})
