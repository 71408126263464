<template>
    <v-expand-transition >
        <v-card tile flat color="transparent"  class="my-3 justify-center text-center " v-show="isShow"  >
            <v-progress-circular
                indeterminate
                color="mainColor"
            ></v-progress-circular>
        </v-card>
    </v-expand-transition>
</template>

<script>
export default {
    name : 'ProgressCurcul',
    props : {
        isShow : {
            default : false
        }
    }
}
</script>