import store from "@/store";
import Vue from "vue";
import GmapVue from 'gmap-vue';

let apiKey = "AIzaSyAyxgAIS9Wxgrj5InrSKiqCQXgQ-Ptc1TA"	//구글에서 발급받은 키 입력

Vue.use(GmapVue, {
    load: {
        key: apiKey,
        language : 'en',	//option
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
})

export default apiKey;