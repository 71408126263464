import { make } from 'vuex-pathify'
import i18n from '@/util/i18n'

// app/ 경로 아래에 사용할 상태변수들 정의
const state = {
    broken: { // 고장신고용
        Availability: i18n.t('store.options.broken.availability'),
        Location: i18n.t('store.options.broken.location'),
        Price: i18n.t('store.options.broken.price'),
        Usable: i18n.t('store.options.broken.usable'),
        Other:  i18n.t('store.options.broken.other'),
    },
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

