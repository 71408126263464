import axios from '../axios'

/**
 * REQUEST FORM
 * /api/{controllerName}/{function}
 */
export async function sendCertifySms(phoneNumber, notCheckDuplicate) { // 핸드폰 인증 발송
    const res = await axios.post('/thirdparty/sendCertifySms', {phoneNumber:phoneNumber, notCheckDuplicate : notCheckDuplicate})
    return res
}

export async function kakaoNaviFind(address) { 
    const res = await axios.post('/thirdparty/kakaoNaviFind', {address:address})
    return res
}

export async function sendNonMember(phoneNumber, transaction_id){ // 비회원 충전 시 충전정보 url발송
    const res = await axios.post('/thirdparty/sendNonMember', {phoneNumber:phoneNumber, transaction_id: transaction_id})
    return res
}

export async function sendCertifyMail(sendData) { // 메일 인증 발송
    const res = await axios.post('/thirdparty/sendCertifyMail', sendData)
    return res
}

export async function getHoliday() { 
    const res = await axios.post('/thirdparty/getHoliday')
    return res
}

export async function GoogleNaviFind(address) { 
    const res = await axios.post('/thirdparty/GoogleNaviFind', {address : address})
    return res
}
