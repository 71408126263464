<template>
    <v-btn icon :ripple="false" @click="$function.movePage($pages.BROKEN_REGI.name, {evseNum : evseNum})">
        <v-icon :color="additionalBtnColor">
            mdi-tools
        </v-icon>
    </v-btn>
</template>

<script>
import EventBus from '@/util/eventBus'

export default {
    name: 'Broken',
    data() {
        return {
            evseNum : null
        }
    },
    props:{
        additionalBtnColor:{
            type:String
        },
    },
    created() {
        EventBus.$on('evseNum', (res) => {
            this.evseNum = res
        })
    }
}
</script>